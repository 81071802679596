export const WORKFLOW_PLAN = 'T';
export const WORKFLOW_TRAVEL_TIME = 'TT';
export const WORKFLOW_SPEED = 'RT';
export const WORKFLOW_TIMELINE = 'TL';

export const restApiUrl = '/server';

// user visible names
export const WORKFLOW_PLAN_NAMES = {
  [WORKFLOW_PLAN]: 'Volumes',
  [WORKFLOW_SPEED]: 'Speeds',
  [WORKFLOW_TRAVEL_TIME]: 'Travel Time',
  [WORKFLOW_TIMELINE]: 'Timeline',
};

export const WORKFLOWS_ALL_ENABLED = [
  WORKFLOW_TIMELINE,
  WORKFLOW_PLAN,
  WORKFLOW_SPEED,
  WORKFLOW_TRAVEL_TIME,
];

export const MAXIMIZE_MAP = 'map';
export const MAXIMIZE_DATA = 'data';

export const BIN_15_MINS = '15';
export const BE_BIN_15_MINS = 15;
export const BIN_60_MINS = '60';
export const BE_BIN_60_MINS = 60;

export const VIZ_MODE_CHART = 'chart';
export const VIZ_MODE_TABLE = 'table';

export const COMPARISON_MODE_MONTH = 'month';
export const COMPARISON_MODE_DAY = 'day';

export enum MapStyle {
  Dark = 'dark',
  Sat = 'sat',
}

export const mapboxApiKey =
  'pk.eyJ1IjoicGZyaWVkbWFuIiwiYSI6ImNscHVjb2xjcjBqNmwyanBscWMwNWZiZnUifQ.QRTJ67oscepSyJARJ-Pgug';

export enum DayTypes {
  Weekday = 'Weekday',
  MeWeekday = 'MeWeekday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
}
