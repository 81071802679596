import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { axiosInstance } from 'axios_middleware';

import { restApiUrl } from 'appConstants';
import { walkmeService } from 'features/login/WalkMe';
import { Polygon } from 'geojson';

export const kUserStatusUnknown = 'unknown';
export const kUserStatusPendingTerms = 'tsandcs';
export const kUserStatusLoggedIn = 'logged in';
export const kUserStatusLoggedOut = 'logged out';

export interface GeoJSON_Point {
  type: string;
  coordinates: Array<number>;
}

export interface ProjectInfo {
  project_name: string;
  project_slug?: string;
  project_center?: GeoJSON_Point;
  project_bbox?: Polygon;
  is_default: boolean;
  available_bins: Array<number>;
  right_hand_drive: boolean;
  uses_metric: boolean;
  has_volume_model: boolean;
  features: Array<string>;
  uses_middle_east_day_types: boolean;
}

export interface UserState {
  username: string;
  projects: Array<ProjectInfo>;
  // eslint-disable-next-line no-restricted-globals
  status: string; // TODO should be string enum
  errors: any;
  initialSearchParams: string;
  has_hidden_features: boolean;
}

const initialState: UserState = {
  username: '',
  projects: [],
  status: kUserStatusUnknown,
  errors: '',
  initialSearchParams: window.location.search,
  has_hidden_features: false,
};

export interface CurrentUserResponse {
  username: string;
  email: string;
  projects: ProjectInfo[];
  pending_terms: boolean;
  has_hidden_features: boolean;
}

export const loginUser = createAsyncThunk(
  // action type string
  'users/login',
  // payloadCreator callback function
  async (credentials: { username: string; password: string }, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        `${restApiUrl}/users/login/`,
        {
          username: credentials.username,
          password: credentials.password,
        },
        {
          // we validate 400 (Bad Request) so we get the error messages in response.data (not available in catch)
          validateStatus(status) {
            return [202, 400].includes(status);
          },
        },
      );
      // console.log(`axios post login response: ${JSON.stringify(response)}`);
      if (response.status === 202) {
        return response.data;
      }
      return thunkAPI.rejectWithValue({ error: response.data });
    } catch (err) {
      console.error(`axios post login error: ${err.message}`);
      return thunkAPI.rejectWithValue({ error: err.message });
    }
  },
);

export const signTerms = createAsyncThunk(
  // action type string
  'users/terms',
  // payloadCreator callback function
  async (
    form_info: {
      email: string;
      name: string;
      title: string;
      org: string;
      org_secondary?: string;
      confirmed: boolean;
      confirmed_date: string;
    },
    thunkAPI,
  ) => {
    try {
      console.log('Sending users/terms with payload', form_info);
      const response = await axiosInstance.post(
        `${restApiUrl}/users/terms/`,
        form_info,
        {
          // we validate 400 (Bad Request) so we get the error messages in response.data (not available in catch)
          validateStatus(status) {
            return [202, 400, 403].includes(status);
          },
        },
      );
      // console.log(`axios post login response: ${jstr(response)}`);
      if (response.status === 202) {
        return response.data;
      }
      return thunkAPI.rejectWithValue({ error: response.data });
    } catch (err) {
      console.error(`axios post terms signature error: ${err.message}`);
      return thunkAPI.rejectWithValue({
        error: { non_field_message: err.message },
      });
    }
  },
);

export const logoutUser = createAsyncThunk(
  // action type string
  'users/logout',
  // payloadCreator callback function
  async (credentials, thunkAPI) => {
    const response = await axiosInstance.post(`${restApiUrl}/users/logout/`);
    console.log(`axios post logout response: ${JSON.stringify(response.data)}`);
    walkmeService.resetWalkmeVariables();
    return response.data;
  },
);

export const checkUserStatus = createAsyncThunk(
  // action type string
  'users/checkStatus',
  // payloadCreator callback function
  async (thunkAPI) => {
    const response = await axiosInstance.get(`${restApiUrl}/users/current/`);
    // console.log(`axios post checkUserStatus response: ${JSON.stringify(response.data)}`);
    walkmeService.addWalkmeVariables(response.data);
    return response.data;
  },
);

export const setUserStateFromSuccessfulUserDetail = (state, payload) => {
  if (!payload.pending_terms) {
    state.status = kUserStatusLoggedIn;
  } else {
    state.status = kUserStatusPendingTerms;
  }
  state.username = payload.username;
  state.projects = payload.projects;
  state.has_hidden_features = payload.has_hidden_features;
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetUser: (state) => {
      // https://redux.js.org/tutorials/essentials/part-5-async-logic
      // Immer lets us update state in two ways: either mutating the existing state value, or returning a new result.
      state = Object.assign(state, initialState);
      state.status = kUserStatusLoggedOut;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.fulfilled, (state, action) => {
        setUserStateFromSuccessfulUserDetail(state, action.payload);
        // console.log(`###### loginUser.fulfilled state: ${JSON.stringify(state)}`);
      })
      .addCase(loginUser.rejected, (state, action: any) => {
        state.status = kUserStatusLoggedOut;
        state.errors = action.payload.error;
        // console.log(`################## loginUser.rejected action: ${JSON.stringify(action)}`);
        // console.log(`################## loginUser.rejected state: ${JSON.stringify(state)}`);
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state = Object.assign(state, initialState); // returning a new state object
        state.status = kUserStatusLoggedOut;
        console.log(
          `logoutUser.fulfilled set state to: ${JSON.stringify(state)}`,
        );
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state = Object.assign(state, initialState); // returning a new state object
        state.status = kUserStatusLoggedOut; // ! is this right?
        state.errors = action.error.message;
        console.log(
          `logoutUser.rejected payload: ${JSON.stringify(action.error)}`,
        );
      })
      .addCase(checkUserStatus.fulfilled, (state, action) => {
        // console.log(`checkUserStatus.fulfilled action: ${JSON.stringify(action)}`);
        setUserStateFromSuccessfulUserDetail(state, action.payload);

        // console.log(`checkUserStatus.fulfilled set state to: ${JSON.stringify(state)}`);
      })
      .addCase(signTerms.fulfilled, (state, action) => {
        setUserStateFromSuccessfulUserDetail(state, action.payload);
      })
      .addCase(checkUserStatus.rejected, (state, action) => {
        // console.log(`checkUserStatus.rejected action: ${JSON.stringify(action)}`);
        state.status = kUserStatusLoggedOut;
        state.errors = action.error.message;
        // console.log(`checkUserStatus.rejected payload: ${JSON.stringify(action)}`);
      });
  },
});

export function getProjectInfoForSlug(user, slug): ProjectInfo | undefined {
  for (let i = 0; i < user.projects.length; i++) {
    if (user.projects[i].project_slug === slug) {
      return user.projects[i];
    }
  }
  return undefined;
}

export function selectUserState(rootState) {
  return rootState.user;
}

export function selectUserStatus(rootState) {
  return selectUserState(rootState).status;
}

const { actions, reducer } = userSlice;
export const { resetUser } = actions;
export default reducer;

export const selectUser = (state) => state.user;
