import React from 'react';

import IconButton from '@mui/material/IconButton';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';

import styles from './InfoHover.module.css';

export function InfoHover({ children }: { children: string }) {
  return (
    <Tooltip describeChild title={children} className={styles.tooltip}>
      <IconButton className={styles.info_hover}>
        <QuestionMarkIcon />
      </IconButton>
    </Tooltip>
  );
}

export function InfoWalkmeCrumb({ elId }: { elId: string }) {
  return (
    <span id={elId} className={`info_walkme_crumb ${styles.info_walkme_crumb}`}>
      <InfoIcon />
    </span>
  );
}
