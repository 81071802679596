import React from 'react';

import { SpeedChart } from 'features/chart/SpeedChart';

import { WorkflowRecent } from 'features/workflow_nrt/WorkflowRecent';
import {
  WORKFLOW_PLAN,
  WORKFLOW_SPEED,
  WORKFLOW_TIMELINE,
  WORKFLOW_TRAVEL_TIME,
} from '../../appConstants';
import {
  getSpecificWorkflowState,
  getWorkflowState,
} from '../../state/workflowSlice';

export class workflowSpeed {
  switchToState(fromWorkflow, state) {
    // console.log('Handling switch from ', fromWorkflow, 'to', state.current, state);
    if ([WORKFLOW_PLAN, WORKFLOW_SPEED].includes(fromWorkflow)) {
      getWorkflowState(state).segmentId = getSpecificWorkflowState(
        state,
        fromWorkflow,
      ).segmentId;
    }
    if (
      [WORKFLOW_TIMELINE, WORKFLOW_SPEED, WORKFLOW_TRAVEL_TIME].includes(
        fromWorkflow,
      )
    ) {
      getWorkflowState(state).targetDate = getSpecificWorkflowState(
        state,
        fromWorkflow,
      ).targetDate;
      getWorkflowState(state).comparison = getSpecificWorkflowState(
        state,
        fromWorkflow,
      ).comparison;
    }
  }
}

export function WorkflowSpeed() {
  return <WorkflowRecent chart={<SpeedChart />} graph_title="Speeds" />;
}
