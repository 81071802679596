import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { LayerContext } from 'state/LayerContext';

import {
  selectTypicalMonth,
  setTypicalMonth,
  selectMaximize,
  selectVizMode,
  selectSegmentId,
  selectProject,
  getWorkflowState,
  getSpecificWorkflowState,
} from 'state/workflowSlice';
import { useGetTypicalMonthsQuery } from 'state/apiSlice';

import { Map } from 'features/map/Map';
import { TaskBar } from 'features/task_bar/TaskBar';
import { PlanningChartHeader } from 'features/workflow_planning/PlanningChartHeader';

import { PlanningMonthPicker } from 'features/task_bar/PlanningMonthPicker';
import { BinSizePicker } from 'features/task_bar/BinSizePicker';
import { CapacityThresholdEditor } from 'features/workflow_planning/CapacityThresholdEditor';
import { PlanningChartVolume } from 'features/chart/PlanningChartVolume';
import { PlanningTableVolume } from 'features/table/PlanningTableVolume';
import { workflowStyles } from 'features/workspace/workspaceUtils';

import { ExportVolumeButton } from 'features/workflow_planning/ExportVolumeButton';

import { VIZ_MODE_CHART, WORKFLOW_PLAN, WORKFLOW_SPEED } from 'appConstants';

import styles from 'features/workspace/Workspace.module.css';
import { NoSegmentSelectedPanel } from '../workspace/NoSegmentSelectedPanel';

export class workflowPlanning {
  switchToState(fromWorkflow, state) {
    if ([WORKFLOW_PLAN, WORKFLOW_SPEED].includes(fromWorkflow)) {
      getWorkflowState(state).segmentId = getSpecificWorkflowState(
        state,
        fromWorkflow,
      ).segmentId;
    }
  }
}

function VisualisationRegion() {
  const maximize = useSelector(selectMaximize);
  const vizMode = useSelector(selectVizMode);
  const segmentId = useSelector(selectSegmentId);

  const { data_viz_style, header_style } = workflowStyles(maximize);

  if (segmentId === undefined) {
    return <NoSegmentSelectedPanel />;
  }

  return (
    <div className={data_viz_style}>
      <div className={header_style}>
        <PlanningChartHeader />
      </div>
      <div className={styles.chart_container_inner}>
        {vizMode === VIZ_MODE_CHART ? (
          <PlanningChartVolume />
        ) : (
          <PlanningTableVolume />
        )}
      </div>
    </div>
  );
}

export function WorkflowPlanning() {
  const maximize = useSelector(selectMaximize);
  const vizMode = useSelector(selectVizMode);
  const project = useSelector(selectProject);

  const { map_style, data_viz_style, header_style } = workflowStyles(maximize);

  // ======================

  const typicalMonth = useSelector(selectTypicalMonth);
  const { data: typicalMonthsInfo } = useGetTypicalMonthsQuery(project);
  const [layer, setLayer] = useState(null);
  const dispatch = useDispatch();

  // memoize so this doesn't change every render.
  const memoizedLayer = useMemo(() => ({ layer, setLayer }), [layer]);

  useEffect(() => {
    let new_layer = null;
    if (typicalMonthsInfo && typicalMonthsInfo.length > 0) {
      if (typicalMonth) {
        new_layer = typicalMonthsInfo.find(
          (info) => info.typicalMonth === typicalMonth,
        )?.layer;
      } else {
        dispatch(setTypicalMonth(typicalMonthsInfo[0].typicalMonth));
      }
    }
    // console.log(`WorkflowPlan set new layer ${new_layer} for typicalMonth ${typicalMonth}`);
    setLayer(new_layer);
  }, [typicalMonthsInfo, typicalMonth, setLayer, dispatch]);

  // ======================

  return (
    <LayerContext.Provider value={memoizedLayer}>
      <div className={styles.workspace_container}>
        <div className={styles.workspace_content}>
          <div className={map_style}>
            <Map />
          </div>
          <VisualisationRegion />
        </div>
        <TaskBar extras={<ExportVolumeButton />}>
          <PlanningMonthPicker />
          <BinSizePicker />
          <CapacityThresholdEditor />
        </TaskBar>
      </div>
    </LayerContext.Provider>
  );
}
