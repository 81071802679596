import React, { useRef, useEffect, useState, useMemo, useContext } from 'react';
import { useSelector } from 'react-redux';

import dayjs from 'dayjs';
import Duration from 'dayjs/plugin/duration';

import {
  selectTargetDate,
  selectTypicalMonth,
  selectSegmentId,
  selectMaximize,
  selectCurrentProjectInfo,
  selectComparison,
} from 'state/workflowSlice';
import { selectSlowdownThreshold } from 'state/workflowSlice';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useGetSegmentInfoQuery } from 'state/apiSlice';

import { LayerContext } from 'state/LayerContext';

import styles from 'features/chart/Chart.module.css';
import { useTravelTimeDataProvider } from 'features/workflow_traveltime/nrtTravelTimeCommon';
import { TravelTimeChartDataExpander } from './TravelTimeChartDataExpander';
import { travelTimeChartOptions } from './travelTimeChartOptions';
import {
  dayMarkerLineBase,
  formatTargetSeriesName,
  formatTypicalSeriesName,
  makeFormatTooltip,
} from './nrtChartCommon';
import { isComparisonMonth } from '../task_bar/ComparisonPicker';

dayjs.extend(Duration);

export function TravelTimeChart() {
  const targetDate: string = useSelector(selectTargetDate);
  const segmentId: string = useSelector(selectSegmentId);
  const slowdownThreshold = useSelector(selectSlowdownThreshold);
  const userProject = useSelector(selectCurrentProjectInfo);
  const comparison = useSelector(selectComparison);

  const chartComponent = useRef(null); // so we can call reflow

  const [chartOptions, setChartOptions] = useState(
    travelTimeChartOptions as any,
  );
  const maximize = useSelector(selectMaximize);

  // ttData from REST api
  const { ttData, comparisonData } = useTravelTimeDataProvider();

  // segmentData layer from REST api
  const { layer } = useContext(LayerContext);
  const { currentData: segmentData } = useGetSegmentInfoQuery(
    { layerId: layer, routeIds: [segmentId] },
    { skip: !layer || !segmentId },
  );
  // memoize chartDataExpander
  const chartDataExpander = useMemo(
    () => targetDate && new TravelTimeChartDataExpander(targetDate),
    [targetDate],
  );

  // memoize segmentProperties
  const segmentProperties = useMemo(
    () => segmentData && segmentData?.segments[segmentId]?.properties,
    [segmentData, segmentId],
  );

  // memoize expanded segmentSpeeds
  const segmentTTs = useMemo(
    () => chartDataExpander?.expandTravelTimes(ttData),
    [ttData, chartDataExpander],
  );
  // console.log(`memoized segmentSpeeds ${JSON.stringify(segmentSpeeds)}`);

  // memoize typicalSpeeds
  const comparisonTTs = useMemo(() => {
    if (comparison) {
      const relavantDate = isComparisonMonth(comparison)
        ? targetDate
        : comparison;
      const comparisonChartDataExpander = new TravelTimeChartDataExpander(
        relavantDate,
        targetDate,
      );
      return comparisonChartDataExpander?.expandTravelTimes(comparisonData);
    } else {
      return undefined;
    }
  }, [comparisonData]);
  // console.log(`###  memoized typicalSpeeds ${JSON.stringify(typicalSpeeds)}`);

  // memoize thresholds
  const slowdownBands = useMemo(
    () =>
      chartDataExpander?.slowdownBands(
        segmentTTs,
        comparisonTTs,
        1 + slowdownThreshold,
        true,
      ),
    [segmentTTs, comparisonTTs, slowdownThreshold, chartDataExpander],
  );

  // update chart with new targetDate, freeflowSpeeds, segmentSpeeds
  useEffect(() => {
    setChartOptions({
      xAxis: {
        plotBands: slowdownBands,
        plotLines: [
          {
            ...dayMarkerLineBase,
            value: dayjs(targetDate),
          },
          {
            ...dayMarkerLineBase,
            value: dayjs(targetDate).add(1, 'day'),
          },
        ],
      },
      series: [
        {
          id: 'typical_tt',
          name: formatTypicalSeriesName(
            comparison,
            targetDate,
            userProject?.uses_middle_east_day_types,
          ),
          data: comparisonTTs || [],
          marker: { enabled: false },
        },
        {
          id: 'segment_tt',
          data: segmentTTs || [],
          name: formatTargetSeriesName(targetDate),
          marker: { enabled: false },
        },
      ],
      tooltip: {
        formatter: makeFormatTooltip(
          slowdownBands,
          ttData?.bin_size,
          userProject?.uses_middle_east_day_types,
          (v) => {
            const duration = dayjs.duration(v, 'seconds');
            return duration
              .format('H [hrs] m [mins] s[s]')
              .replace(/^0 hrs /, '')
              .replace(/^1 hrs /, '1 hr ')
              .replace(/^1 mins /, '1 min ')
              .replace(/^0 mins /, '');
          },
          targetDate,
          comparison,
        ),
      },
    });
  }, [segmentTTs, comparisonTTs, slowdownBands, ttData]);

  useEffect(() => {
    const chart = chartComponent.current?.chart;
    if (chart) {
      chart.reflow();
    }
  }, [maximize]);

  return (
    <div className={styles.chart}>
      <HighchartsReact
        ref={chartComponent}
        highcharts={Highcharts}
        containerProps={{ style: { height: '100%', width: '100%' } }}
        options={chartOptions}
      />
    </div>
  );
}
