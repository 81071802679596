import React, { useContext, PropsWithChildren } from 'react';

import { useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

import { selectCurrentProjectInfo, selectSegmentId } from 'state/workflowSlice';
import { useGetSegmentInfoQuery } from 'state/apiSlice';
import { formatSpeed } from 'features/common/utils';

import { LayerContext } from 'state/LayerContext';

import { Segment } from 'appTypes';

import styles from 'features/task_bar/Taskbar.module.css';
import { InfoHover, InfoWalkmeCrumb } from '../info_hover/InfoHover';

export function StyledCell({ children }: PropsWithChildren) {
  return <TableCell className={styles.TableCell}>{children}</TableCell>;
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function SegmentProperties(
  segment: Segment,
  extras?: Array<React.ReactNode>,
  uses_metric?: boolean,
) {
  const segmentProperties = segment?.properties;
  const segmentInfo = `${segmentProperties.name}`;

  // console.log(`SegmentProperties: ${JSON.stringify(segmentProperties)}`);

  return (
    <div className={styles.segment_info}>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={styles.TableCell} colSpan={2}>
                {segmentInfo}
              </TableCell>
            </TableRow>
            {segmentProperties.freeflow_speed_mph && (
              <TableRow>
                <StyledCell>
                  Freeflow speed
                  <InfoWalkmeCrumb elId="freeflow-speed" />
                </StyledCell>
                <StyledCell>
                  {formatSpeed(
                    segmentProperties.freeflow_speed_mph,
                    uses_metric,
                  )}
                </StyledCell>
              </TableRow>
            )}
            {segmentProperties.cardinal_dir && (
              <TableRow>
                <StyledCell>Direction</StyledCell>
                <StyledCell>
                  {capitalizeFirstLetter(segmentProperties.cardinal_dir)}
                </StyledCell>
              </TableRow>
            )}
            {
              // segmentProperties.lane_count
              // && (
              //   <TableRow>
              //     <StyledCell>Lanes</StyledCell>
              //     <StyledCell>{segmentProperties.lane_count || 'unknown'}</StyledCell>
              //   </TableRow>
              // )
            }
            {extras}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

function NoSegmentSelected() {
  // console.log(`xxx: ${JSON.stringify(segmentData)}`);

  return (
    <div>
      <div className={styles.segment_info}>No segment selected</div>
    </div>
  );
}

export function SegmentInfo({
  extras = undefined,
}: {
  extras?: Array<React.ReactNode>;
}) {
  const segmentId = useSelector(selectSegmentId);
  const userProject = useSelector(selectCurrentProjectInfo);

  // cached so no need to memoize
  const { layer } = useContext(LayerContext);
  const { currentData: segmentData } = useGetSegmentInfoQuery(
    { layerId: layer, routeIds: [segmentId] },
    { skip: !layer || !segmentId },
  );

  const segment = segmentData && segmentData?.segments[segmentId];

  if (segment)
    return SegmentProperties(segment, extras, userProject.uses_metric);
  return <NoSegmentSelected />;
}
